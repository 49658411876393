import React from 'react';
import { AdminBreadCrumbHeader } from '../admin-bread-crumb-header';
import { Content } from '../../shared/containers/content';
import { Button, Paper } from '@mui/material';
import { StandardRouteList } from './standard-route-list';
import { HereMap } from '../../here-maps/here-map';
import { StandardRouteLines } from '../../here-maps/standard-route-lines';
import { ModalDialog } from '../../shared/containers/modal-dialog';
import { L } from 'harmony-language';
import { CreateStandardRoute, StandardRoute } from '../../../types';
import { EditCreateStandardRoute } from './edit-create-standard-route';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { useAddStandardRouteMutation } from '../../../api/mutations/add/use-add-standard-route-mutation';
import { useEditStandardRouteMutation } from '../../../api/mutations/edit/use-edit-standard-route-mutation';
import { OrganizationLocationBoxes } from '../../here-maps/organization-location-boxes';

export const StandardRouteManagement: React.FC = () => {
    const [shownRoutes, setShownRoutes] = React.useState<number[]>([]);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [selectedStandardRoute, setSelectedStandardRoute] = React.useState<StandardRoute | null>(null);
    const { data: standardRoutes = [] } = useOrganizationQuery<StandardRoute[]>(OrgQueryKeys.standardRoutes);
    const { mutate: addStandardRoute } = useAddStandardRouteMutation();
    const { mutate: editStandardRoute } = useEditStandardRouteMutation();

    const filteredStandardRoutes = React.useMemo(() => {
        return standardRoutes.filter(x => !x.deletedAt);
    }, [standardRoutes]);

    const renderShownRoutes = React.useMemo(() => {
        return (
            shownRoutes.map(srId => {
                const item = filteredStandardRoutes.find(x => x.id === srId);
                if (item) {
                    return <StandardRouteLines key={srId} flexiblePolyline={item.flexiblePolyline} />
                }
            })
        )
    }, [shownRoutes]);

    const handleEditOpen = (s: StandardRoute) => {
        setSelectedStandardRoute(s);
        setDialogOpen(true);
    }

    const onSubmit = (standardRoute: StandardRoute | CreateStandardRoute) => {
        if ('id' in standardRoute) {
            editStandardRoute(standardRoute);
        } else {
            addStandardRoute(standardRoute);
        }

        setSelectedStandardRoute(null);
        setDialogOpen(false);
    };

    return (
        <>
            <AdminBreadCrumbHeader />
            <Content style={{ height: '100%', overflow: 'hidden' }}>
                <Paper
                    sx={{
                        display: 'flex',
                        height: '100%',
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '1em' }}>
                        <Button
                            variant='contained'
                            onClick={() => setDialogOpen(true)}
                        >
                            {L.createNew()}
                        </Button>
                        <StandardRouteList
                            standardRoutes={filteredStandardRoutes}
                            shownRoutes={shownRoutes}
                            setShownRoutes={setShownRoutes}
                            handleEditOpen={handleEditOpen}
                        />
                    </div>
                    <HereMap
                        centerPosition={{ lat: 42.503648, lng: -94.160726 }}
                    >
                        {renderShownRoutes}
                        <OrganizationLocationBoxes />
                    </HereMap>
                    <ModalDialog
                        open={dialogOpen}
                        onClose={() => {
                            setSelectedStandardRoute(null);
                            setDialogOpen(false);
                        }}
                        title={selectedStandardRoute ? L.editStandardRoute() : L.addStandardRoute()}
                    >
                        <EditCreateStandardRoute
                            existingStandardRoute={selectedStandardRoute}
                            onSubmit={onSubmit}
                            filteredStandardRoutes={filteredStandardRoutes}
                        />
                    </ModalDialog>
                </Paper>
            </Content>
        </>
    );
};
