import AutorenewIcon from '@mui/icons-material/Autorenew';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { IncludedDates } from 'harmony-constants';
import { L } from 'harmony-language';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { QueryKeys } from '../../../api/config';
import { AgisticsContext } from '../../../app/agistics-context';
import { getWorkingWeek, localNow } from '../../../utils/date-time-utils';
import { useUpdateUserPreferences } from '../hooks/use-update-user-preferences';
import { AgisticsDateRange } from '../inputs/agistics-date-range';
import { AgisticsMultiSelectWithPrefs } from '../multi-select/agistics-multi-select-with-prefs';
import { useLoadOrderGroup } from './utils/use-load-order-group';

const FilterButton = styled(Button)({
    width: '100%',
    overflow: 'visible'
});

const WeekPresetButton = React.memo((props) => {
    const { week, isActive, setDateFilter, labelOverride } = props;

    const label = labelOverride ? labelOverride : L.weekNumber(week.weekNumber);

    return (
        <Tooltip title={week.label} placement='top'>
            <FilterButton variant='contained'
                color={isActive ? 'secondary' : 'inherit'}
                onClick={() => {
                    setDateFilter({
                        preset: week.weekNumber,
                        from: week.sundayIso,
                        to: week.saturdayIso
                    });
                }}><Typography variant='inherit' noWrap
                    style={{ overflow: 'visible' }}>{label}</Typography></FilterButton>
        </Tooltip>
    );
});

WeekPresetButton.propTypes = {
    week: PropTypes.object,
    isActive: PropTypes.bool,
    setDateFilter: PropTypes.func,
    labelOverride: PropTypes.string
};

export const DateFiltering = (props) => {
    const { onlyCustom, tableKey, refetch } = props;
    const { dateFilters, updateDateFilters } = useContext(AgisticsContext);
    const dateFilter = dateFilters[tableKey];
    const orderGroup = useLoadOrderGroup();
    const { preferences } = useUpdateUserPreferences();
    const [includedDates, setIncludedDates] = React.useState(preferences.includedDates || []);

    const setDateFilter = (dateFilter) => {
        orderGroup.remove();
        if (dateFilter?.preset !== 'custom') {
            dateFilter.includedDates = [];
        } else {
            // 1st load, need to get dates from preferences if exists
            if (!dateFilter.includedDates) {
                dateFilter.includedDates = preferences.includedDates || [];
            }
        }

        updateDateFilters(tableKey, dateFilter);
    };

    const lastWeek = React.useMemo(() => getWorkingWeek(-1), []);
    const week0 = React.useMemo(() => getWorkingWeek(0), []);
    const week1 = React.useMemo(() => getWorkingWeek(1), []);
    const week2 = React.useMemo(() => getWorkingWeek(2), []);

    const isTodayPreset = dateFilter?.preset === 'today';
    const isCustomPreset = dateFilter?.preset === 'custom';
    const isLastWeekPreset = dateFilter?.preset === lastWeek.weekNumber;
    const isWeek0Preset = dateFilter?.preset === week0.weekNumber;
    const isWeek1Preset = dateFilter?.preset === week1.weekNumber;
    const isWeek2Preset = dateFilter?.preset === week2.weekNumber;

    const includedDatesVisable = !orderGroup.guid && tableKey !== QueryKeys.productionCardGroups;

    const includedDatesMultiItems = React.useMemo(() => {
        return Object.values(IncludedDates).map(x => ({ id: x.id, value: L[x.name]() }))
    }, []);

    return (
        <Grid container spacing={2} alignItems='center' sx={{ minHeight: '64px' }}>
            {!onlyCustom &&
                <>
                    <Grid item>
                        <Button
                            variant='contained'
                            color='inherit'
                            startIcon={<AutorenewIcon />}
                            onClick={refetch}>{L.refresh()}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2} lg={1} sx={{ maxWidth: 'unset' }}>
                        <FilterButton
                            variant='contained'
                            color={isTodayPreset ? 'secondary' : 'inherit'}
                            onClick={() => {
                                setDateFilter({
                                    preset: 'today',
                                    from: localNow().startOf('day').toISOString(),
                                    to: localNow().endOf('day').toISOString()
                                });
                            }}
                        >
                            <Typography variant='inherit' noWrap style={{ overflow: 'visible' }}>{L.today()}</Typography>
                        </FilterButton>
                    </Grid>
                    <Grid item xs={12} sm={3} md={2} lg={1} sx={{ maxWidth: 'unset' }}>
                        <WeekPresetButton week={lastWeek} isActive={isLastWeekPreset} setDateFilter={setDateFilter} labelOverride={L.lastWeek()} />
                    </Grid>
                    <Grid item xs={12} sm={3} md={2} lg={1} sx={{ maxWidth: 'unset' }}>
                        <WeekPresetButton week={week0} isActive={isWeek0Preset} setDateFilter={setDateFilter} />
                    </Grid>
                    <Grid item xs={12} sm={3} md={2} lg={1} sx={{ maxWidth: 'unset' }}>
                        <WeekPresetButton week={week1} isActive={isWeek1Preset} setDateFilter={setDateFilter} />
                    </Grid>
                    <Grid item xs={12} sm={3} md={2} lg={1} sx={{ maxWidth: 'unset' }}>
                        <WeekPresetButton week={week2} isActive={isWeek2Preset} setDateFilter={setDateFilter} />
                    </Grid>
                    <Grid item xs={12} sm={3} md={2} lg={1} sx={{ maxWidth: 'unset' }}>
                        <Tooltip title={L.customDate()} placement='top'>
                            <FilterButton variant='contained'
                                color={isCustomPreset ? 'secondary' : 'inherit'}
                                onClick={() => setDateFilter({ preset: 'custom' })}>{L.custom()}</FilterButton>
                        </Tooltip>
                    </Grid>
                </>}
            {isCustomPreset &&
                <>
                    {includedDatesVisable && <Grid item xs={12} sm={12} md={4} lg={1.6}>
                        <AgisticsMultiSelectWithPrefs
                            selectedIds={includedDates}
                            setSelectedIds={setIncludedDates}
                            items={includedDatesMultiItems}
                            label={L.includedDates()}
                            prefKey='includedDates'
                            removeMode={false}
                            presist={true}
                            additionalOnChange={(change) => {
                                setDateFilter({
                                    ...dateFilter,
                                    includedDates: change,
                                });
                            }}
                        />
                    </Grid>}
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <AgisticsDateRange
                            startDate={dateFilter.from}
                            endDate={dateFilter.to}
                            setStartDate={(date) => setDateFilter({ ...dateFilter, from: date })}
                            setEndDate={(date) => setDateFilter({ ...dateFilter, to: date })}
                        />
                    </Grid>
                </>
            }
        </Grid>
    );
};

DateFiltering.propTypes = {
    onlyCustom: PropTypes.bool,
    tableKey: PropTypes.string,
    refetch: PropTypes.func,
};
