import React, { useEffect } from 'react';
import { HereMapContext } from './here-map-context';
import { createPolyline, lineStringFromEncodedPolyline } from '../../services/here-maps/here-map-wrapper';
import { useDraggableStandardRoute } from './use-draggable-standard-route';
import { HerePoint } from '../../types';
import { removeMapObjectById } from './standard-route-helpers';

interface HereMapLineProps {
    flexiblePolyline: string;
    setDraggableRoute?: (h: HerePoint[]) => void;
}

export const StandardRouteLines: React.FC<HereMapLineProps> = (props) => {
    const { flexiblePolyline, setDraggableRoute } = props;
    const context = React.useContext(HereMapContext);
    setDraggableRoute && useDraggableStandardRoute(flexiblePolyline, setDraggableRoute);

    useEffect(() => {
        // dont keep track of this in useState, will be unreliable
        let polylineId = 0;
        if (context.map) {
            const lineString = lineStringFromEncodedPolyline(flexiblePolyline);

            const polyline = createPolyline(lineString, {
                style: {
                    lineWidth: 4,
                    strokeColor: 'rgba(0, 255, 0, 1)',
                },
            });

            polylineId = polyline.getId();
            context.map.addObject(polyline);
        }

        return () => {
            if (context.map) {
                removeMapObjectById(context, polylineId);
            }
        }
    }, [flexiblePolyline, context.map]);

    return null;
};
